import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

// components
import Layout from "@modules/layout"
import Seo from "@modules/seo"

import Leistungen from "@modules/leistungen"
import bukephalonPDF from "@assets/pdfs/ammerthaler_institut_bukephalos_flyer.pdf"

const Coaching = ({ location, data }: any) => {
  const {
    seminare_und_workshops_startbild_l,
    seminare_und_workshops_startbild_s,
    seminare_und_workshops_startbild_m,
    kundenzufriedenheit_icon,
    kostensenkung_icon,
    mitarbeitermotivation_icon,
    ammerthaler_Institut_Flyer_Coaching,
    fylerPDF,
    bukephalon_flyer,
    gelassenheit,
  } = data

  const coachingL: any = getImage(seminare_und_workshops_startbild_l)
  const coachingM: any = getImage(seminare_und_workshops_startbild_m)
  const coachingS: any = getImage(seminare_und_workshops_startbild_s)
  const flyer: any = getImage(ammerthaler_Institut_Flyer_Coaching)
  const bukephalon: any = getImage(bukephalon_flyer)
  const gelassenheitImage: any = getImage(gelassenheit)

  return (
    <Layout location={location}>
      <Seo title="Seminare & Workshops">
        <Leistungen
          sectionH1={
            <>
              Seminare & <span>Workshops</span>
            </>
          }
          sectionH2="Bei uns finden Sie aktuelle Wissens- und Knowhow-Vermittlung in Seminaren und Workshops. Wir möchten Sie inspirieren - beruflich wie auch persönlich!"
          introImage={coachingL}
          introImageS={coachingS}
          introImageM={coachingM}
          introImageAlt="test"
          intro={[
            {
              title: "Über unsere Seminare & Workshops",
              text: "Die Themenauswahl zu aktuellen wie auch bewährten Seminar-Themen entspricht den individuellen Lernbedürfnissen von Fach- und Führungskräften. Formate sind möglich in Präsenz und natürlich auch hybrid. Workshops werden mit Ihnen immer maßgeschneidert zu unterschiedlichen Themen wie Visions-Workshops, Entwicklung eines neuen Führungsleitbilds, Entwicklung von Mentoring-Konzepten im Kontext demografischen Wandels/Wissensmanagements etc.",
            },
            {
              title: "Wie wir vorgehen",
              text: "In den Trainingseinheiten üben Sie neues Wissen - vermittelt durch unsere bewährten Impulsvorträge - mit unterschiedlichen Trainingsmethoden ein. Wir bieten lhnen aktuelle Erkenntnisse aus Psychologie und Hirnforschung auf der Basis unserer langjährigen Tätigkeit in Wirtschaftsunternehmen und Non-Profit-Organisationen. Sie gewinnen Sicherheit und Motivation für Ihre Umsetzung in die Berufspraxis.",
            },
          ]}
          supportTitle="Wir Unterstützen Sie"
          supportImage={flyer}
          supportImageAlt="test"
          supportUl={[
            {
              icon: flyer,
              text: "Life-Balance entwickeln",
            },
            {
              icon: flyer,
              text: "Gelingende Kommunikations- und  Konfliktkultur schaffen",
            },
            {
              icon: flyer,
              text: "Resilienz und mentale Stärke verbessern",
            },
          ]}
          profitTitle="Wie sie davon profitieren"
          quoteActive
          quote="Ich habe keine Angst vor Stürmen. Ich lerne, wie ich mein Schiff steuern muss."
          quoteAuthor="- Louisa May Alcott"
          profitUl={[
            {
              icon: kundenzufriedenheit_icon,
              iconAlt: "test",
              text: "Erhöhung der Kundenzufriedenheit",
              key: kundenzufriedenheit_icon.childImageSharp.id,
            },
            {
              icon: mitarbeitermotivation_icon,
              iconAlt: "test",
              text: "Steigende Mitarbeitermotivation",
              key: mitarbeitermotivation_icon.childImageSharp.id,
            },
            {
              icon: kostensenkung_icon,
              iconAlt: "test",
              text: "Kostensenkung",
              key: kostensenkung_icon.childImageSharp.id,
            },
          ]}
          feedback={[
            {
              text: (
                <p>
                  Ich möchte mich hiermit für die Unterstützung und das
                  persönliche Coaching zu einem Konfliktgespräch bedanken. Die
                  Gespräche sind zur vollsten Zufriedenheit aller beteiligten
                  gelaufen, so das ein weiteres Coaching nicht mehr notwendig
                  ist.
                </p>
              ),
              author: "Michael Pertsch",
              job: "SWM, Energiedaten- und Messervice",
            },
            {
              text: (
                <p>
                  <b>Vortrag auf der Klausurtagung des ISB</b>
                  <br />
                  Auf der Grundlage eines Vortrags zum Thema "Agile Führung"
                  konnte Frau Lehner im Kreis der Abteilungsleitungen wichtige
                  Anregungen zur Diskussion eines modernen
                  Führungsverständnisses geben. Dabei beeindruckte ihre
                  souveräne, auf einem breit gefächerten Wissen und aktuellen
                  Erkenntnissen beruhende Vortragsweise. Wir haben inspirierende
                  Impuse von ihr bekommen.
                </p>
              ),
              author: "Dr. Karin E. Oechslein",
              job: "Direktorin Staatsinstitut für Schulqualität und Bildungsforschung, München",
            },
            {
              text: (
                <p>
                  <b>Gelungenes Seminar</b>
                  <br />
                  „Dieser einhelligen Meinung waren die Teilnehmer/innen an der
                  städtischen Fortbildungsveranstaltung am 24. April im
                  Schulungsraum des Stettner-Hauses. In Zusammenarbeit mit der
                  Volkshochschule Schwandorf hatte OB Helmut Hey, der gemeinsam
                  mit 12 weiteren Mitarbeitern/innen selbst am Seminar teilnahm,
                  eingeladen. Gewählt wurde das Thema „Gesprächsführung als
                  Führungsinstrument“, das bei den Kollegen/innen auf reges
                  Interesse stieß. Die Leitung hatte Diplompädagogin (Univ.)
                  Christine Lehner vom Ammerthaler Institut übernommen. Die
                  VHS-Geschäftsführung hatte mit der versierten Referentin eine
                  sehr gute Wahl getroffen. Frau Lehner vermittelte in
                  komprimierter Form die wesentlichen Inhalte des Themas. In
                  kurzen Fallbeispielen konnten die Teilnehmer wertvolle Tipps
                  und Anregungen auch gleich praktisch anwenden. Fazit: „Ein
                  abwechslungsreiches und gelungenes Seminar.“ Frau Lehner
                  erhielt zum Schluss ihrer Ausführungen zu Recht einen lang
                  anhaltenden Applaus!“
                </p>
              ),
              author: "Stadt Schwandorf, Pressestelle",
              job: "",
            },
            {
              text: (
                <p>
                  "Christine Lehner war Trainerin an unseren Händler-Tagen mit
                  Workshops zum Thema "Think limbic - Arbeiten mit
                  Wohlfühlfaktor". Auf die Ihr eigene, unaufdringliche aber sehr
                  professionelle Art, lud sie die Seminar-Teilnehmer ein, sich
                  selbst und andere besser verstehen zu lernen. Limbic mind war
                  das Thema. Sie vermittelte Fachwissen und vielschichtige
                  Einsichten mit Fingerspitzengefühl und Standing. Wohltuend, -
                  eine Trainerin mit ganz viel Know-how, dafür aber ganz ohne
                  lästige Profilsucht!"
                </p>
              ),
              author: "Karen Habermann",
              job: "Brand Manager, GRAMMER OFFICE, SID Seating GmbH",
            },
            {
              text: (
                <p>
                  Unsere Ideen und Zielsetzungen für Workshops oder Tagungen
                  wurden durch die Trainerinnen des Ammerthaler Instituts stets
                  professionell, mit großem Verständnis und Gespür für unsere
                  ganz individuellen Anforderungen und Rahmenbedingungen
                  aufgegriffen und in Konzepte gegossen.
                  <br />
                  Bei der Durchführung der Workshops konnten wir jedes Mal auf
                  die engagierte und kreative Vorbereitung, Durchführung und
                  Nachbereitung der beiden zählen.
                  <br />
                  Kurz um danken wir Frau Lehner und Frau Weihe herzlich für die
                  vertrauensvolle Zusammenarbeit, die wichtige Unterstützung und
                  ihre kreativen und individuellen Lösungen.
                </p>
              ),
              author:
                "Abteilung Kommunikation und Marketing, BWI Leistungsverbund",
              job: "",
            },
            {
              text: (
                <p>
                  Sabine Weihe unterrichtet seit 13 Jahren erfolgreich in der
                  berufsbegleitenden Weiterbildung an der Technischen Hochschule
                  Rosenheim Vorlesungen zum Thema Führung, Autorität und Werte.
                  Die Absolventen loben die hohe Professionalität der Dozentin
                  und der Vorlesungsinhalte, den generierten persönlichen und
                  beruflichen Mehrwert und das intensive und offene Arbeiten in
                  einer kleiner motivierten Gruppe. Die Mischung zwischen
                  Theorie und Praxis wird von den Teilnehmenden sehr geschätzt.
                  Wir freuen uns auf die weitere Zusammenarbeit mit dem
                  Ammerthaler Institut.
                </p>
              ),
              author: "Dipl.-Ing. (FH) Sylvia Posch",
              job: "Programm-Managerin, Academy for Professionals, Technische Hochschule Rosenheim",
            },
            {
              text: (
                <p>
                  <b>
                    Auch ich möchte mich bei Ihnen für die Durchführung des
                    Seminars in unserem Hause bedanken! Ich habe sehr positive
                    Rückmeldungen von den Teilnehmern erhalten.
                  </b>
                  <br />
                  Ein Teilnehmer hat den Wunsch geäußert, im nächsten Jahr einen
                  Follow-Up-Tag stattfinden zu lassen. Gerne werde ich diesen
                  Gedanken bei der Gestaltung der Folgebroschüre
                  berücksichtigen. Ich freue mich, Sie für weitere Schulungen
                  einplanen zu dürfen.
                </p>
              ),
              author: "Andrea Eckl",
              job: "Andrea Eckl, Corporate Human Resources, Schefenacker AG",
            },
            {
              text: (
                <p>
                  <b>
                    Fr. Weihe hat die Präsentations-Workshops stets zu unserer
                    vollsten Zufriedenheit und mit ausgezeichneter Rückmeldung
                    von den Teilnehmern durchgeführt.
                  </b>
                  <br />
                  Fr. Weihe führt seit 1998 als freie Trainerin für die CLAAS
                  KGaA mbH in Harsewinkel in regelmäßigen Abständen
                  Präsentations-Workshops im Rahmen unserer
                  Führungskräfteentwicklung durch.
                  <br />
                  Zielgruppe sind sowohl Führungskräfte der 2. Führungsebene als
                  auch Fach- und Führungsnachwuchskräfte, die häufig mit
                  Präsentationsaufgaben konfrontiert werden. Die Schwerpunkte
                  liegen dabei auf die persönliche Wirkung sowie den Einsatz
                  neuer Techniken für die Durchführung von Präsentationen sowohl
                  für den internen Bereich als auch für externe Kunden. Dabei
                  beinhaltet das Seminar kurze Theorie-Inputs und die
                  ausführliche Analyse des Präsentationsverhaltens der
                  Teilnehmer."
                </p>
              ),
              author: "Claudia Joost",
              job: "CLAAS KGaA mbH ",
            },
            {
              text: (
                <p>
                  Wir danken Sabine Weihe herzlich für ihre lebendige und
                  praxisnahe Workshop-Reihe, die sie für unsere
                  Nachwuchsführungskräfte konzipiert und durchgeführt hat. Die
                  Teilnehmer waren von den zahlreichen Übungen, Rollenspielen
                  und Reflexionsphasen begeistert und empfanden die Workshops
                  als ausgesprochen erkenntnisreich. Außerdem waren sie
                  fasziniert, wie mühelos und schnell sich Sabine als externe
                  Trainerin auf unsere spezifische Unternehmenskultur
                  eingestellt hat. Das kann auch ich aus der Perspektive der
                  Personalentwicklung bestätigen. Die gemeinsame Zusammenarbeit
                  war unkompliziert, flexibel und überzeugend. Wir haben unsere
                  Nachwuchsführungskräfte gerne und ruhigen Gewissens in Sabines
                  Hände gegeben.
                </p>
              ),
              author: "Linda Fromm",
              job: "Senior Referentin Personalentwicklung,  CHECK24 - Das Vergleichsportal",
            },
            {
              text: (
                <p>
                  "
                  <b>
                    Im Rahmen eines Benchmark-Projektes haben wir bei einem
                    unserer Kunden Defizite im Bereich Personalführung
                    festgestellt. Als eine wesentliche Maßnahme zur Verbesserung
                    dieser Situation wurde das Thema "Zielvereinbarungen"
                    gemeinsam mit der Unternehmensleitung erkannt und definiert.{" "}
                  </b>{" "}
                  <br />
                  Im Zuge der Umsetzung dieser Maßnahme haben wir gemeinsam mit
                  Ihnen Workshops geplant und durchgeführt, mit denen die
                  Grundlagen für die Umsetzung der Zielvereinbarungen in dem
                  Kundenunternehmen geschaffen wurden. Dabei haben Sie Ihre
                  Kompetenzen und Erfahrungen als Personaltrainerin in bester
                  Weise einbringen können. Die positiven Reaktionen unserer
                  Kunden bestätigen dies eindrucksvoll. "
                </p>
              ),
              author: "Lothar Ophey",
              job: "InnoTech",
            },
          ]}
          recommendationTitle="Weitere Punkte unseres Leistungsspektrums"
          recommendataion={{
            outdoor: true,
            seminare_und_workshops: false,
            organisationsentwicklung: true,
            coaching: true,
          }}
          fylerUrl={fylerPDF.publicURL}
          bewaehrteSeminarthemen={[
            "Bewährte Seminarthemen:",
            "„Selbstorganisation (Life-Balance)“",
            "„Gelingende Kommunikations- und Konfliktkultur“",
            "„Den Führungsalltag souverän meistern“",
            "„Veränderungsprozesse gestalten“",
          ]}
          aktuelleSeminarthemen={[
            "Aktuelle Seminarthemen:",
            "„Umgang mit Komplexität“",
            "„Resilienz/mentale Stärke – in herausfordernden Situationen handlungsfähig bleiben“",
            "„New Work – die Arbeitswelt 4.0 analog und digital meistern“",
          ]}
          seminareActive
          seminare={[
            {
              title: "Kompetenz Gelassenheit",
              text: "Mentales Training mit Outdoor-Elementen",
              h4: "Um was es geht",
              p: "Die zunehmende Komplexität und Beschleunigung des heutigen Lebens erfordert den bewussten Umgang mit Stress. Gelassene Menschen haben eine hohe Selbstwirksamkeitserwartung/Resilienz, also die selbstbewusste Zuversicht, schwierige Situationen in eigener Kompetenz meistern zu können. Wir vermitteln ihnen bei diesem Workshop die wichtigsten Kompetenzen im Umgang mit Stress!",
              img: gelassenheitImage,
              imgAlt: "test",
            },
            {
              title: "Bukephalos",
              text: "Mangement-Training mit Pferden",
              h4: "Um was es geht",
              p: (
                <>
                  Bei diesem Workshop geht es um Selbstreflexion der gewohnten
                  Denk- und Verhaltensmuster und mentale Strategien der
                  Selbststeuerung. In der Begegnung mit Pferden entsteht eine
                  intensive, mental-körperlich-­emotionale Herausforderung, die
                  im unmittelbaren Erleben die eigenen Denk- und Handlungsmuster
                  erfahrbar und gestaltbar macht.
                  <br />
                  Das Training kann offen für Einzelpersonen und als
                  geschlossenes Firmentraining gebucht werden. Pro Training sind
                  es 4 bis 6 Teilnehmer. Es dauert 3 Tage lang.
                </>
              ),
              url: bukephalonPDF,
              img: bukephalon,
              imgAlt: "test",
              buttonActive: true,
            },
          ]}
        />
      </Seo>
    </Layout>
  )
}

export const query = graphql`
  query {
    fylerPDF: file(
      absolutePath: { regex: "/ammerthaler_institut_coaching_flyer/" }
    ) {
      publicURL
    }
    seminare_und_workshops_startbild_l: file(
      relativePath: { regex: "/seminare_und_workshops_startbild_l/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    seminare_und_workshops_startbild_s: file(
      relativePath: { regex: "/seminare_und_workshops_startbild_s/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    seminare_und_workshops_startbild_m: file(
      relativePath: { regex: "/seminare_und_workshops_startbild_m/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    kundenzufriedenheit_icon: file(
      relativePath: { regex: "/kundenzufriedenheit_icon/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    kostensenkung_icon: file(relativePath: { regex: "/kostensenkung_icon/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    mitarbeitermotivation_icon: file(
      relativePath: { regex: "/mitarbeitermotivation_icon/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    ammerthaler_Institut_Flyer_Coaching: file(
      relativePath: { regex: "/Ammerthaler_Institut_Flyer_Coaching/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    bukephalon_flyer: file(relativePath: { regex: "/bukephalon_flyer/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    gelassenheit: file(relativePath: { regex: "/gelassenheit.jpg/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
  }
`

export default Coaching
